import toast from 'react-hot-toast'
import { IoCopyOutline, IoWarningOutline } from 'react-icons/io5'

const url = new URL(document.URL)
const code = url.searchParams.get('code')

export function Nuvemshop() {
    const copiarCodigo = () => {
        if (code) {
            navigator.clipboard.writeText(code)
            toast.success('Código copiado para a área de transferência')
        }
    }

    return (
        <div className="h-screen w-screen flex flex-col justify-center items-center bg-stone-100">
            <div className="flex items-center justify-center gap-4 max-w-[600px] mb-5 text-stone-600">
                <img src="/assets/agulhao/logo.svg" alt="Agulhão" className="w-16 invert-[0.7]" />
                <div>
                    <p className="text-3xl font-bold">Agulhão</p>
                    <p>Sistema especialista em confecção</p>
                </div>
            </div>
            <div className="bg-white p-10 rounded-xl border max-w-[600px] w-full grid gap-4">
                <p className="text-2xl font-bold text-center">Código para obter acesso a API da NuvemShop</p>

                <div className="bg-stone-50 border border-stone-200 text-stone-800 p-4 rounded-lg text-sm flex gap-4">
                    <IoWarningOutline className="text-xl mt-1" />
                    <div>
                        <p className="font-bold">Atenção</p>
                        <p>
                            Este código é <span className="font-bold text-orange-600">válido por apenas 5 minutos</span>
                        </p>
                    </div>
                </div>

                <div className="p-5 text-center bg-stone-700 rounded-lg text-stone-200">
                    <p className="font-mono">{code}</p>
                </div>

                <button
                    onClick={copiarCodigo}
                    className="bg-stone-50 border-stone-400 hover:bg-stone-200 border p-2 rounded-lg flex items-center justify-center gap-2 transition-all duration-200">
                    Copiar
                    <IoCopyOutline />
                </button>

                <div className="text-sm">
                    <p className="font-bold">O que devo fazer com o código?</p>
                    <p>Cole este código no campo indicado na tela de integrações da NuvemShop no sistema Agulhão e clique em ‘Vincular’.</p>
                </div>
            </div>

            <a href="/" className="mt-4 text-stone-400 hover:underline hover:text-stone-800">
                Quero conhecer mais o sistema
            </a>
        </div>
    )
}
