import sessoesIndustria from '../json/modulos/industria.json'

import Preco from '../components/Preco'
import Sessao from '../components/Sessao'
import { DivLayout } from '../components/divLayout'

export default function Industria() {
    return (
        <DivLayout>
            <div className="flex justify-center items-center flex-col">
                <div className="text-center pt-12 text-lg p-5">
                    <div className="my-12 mb-24 font-light">
                        <h1 className="text-4xl">Agulhão para Indústrias</h1>
                        <p className="mt-2">
                            Focado em ser um sistema prático e completo, tudo feito para sua indústria{' '}
                            <span className="text-primary font-semibold">crescer e voar</span>
                        </p>
                    </div>
                    <div className="mb-10">
                        <img className="w-auto" src="assets/agulhao/screenshots/sistema.png" alt="imagem do sistema" />
                    </div>
                </div>
                <div className="max-w-7xl px-9">
                    {sessoesIndustria.map((dados, i) => (
                        <Sessao key={i} {...dados} layout={i % 2} />
                    ))}
                </div>

                <Preco />
            </div>
        </DivLayout>
    )
}
