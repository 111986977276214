import { useEffect, useState } from 'react'
import { FaArrowUp } from 'react-icons/fa'
import { AnimatePresence, motion } from 'motion/react'

export function VolteParaTopo() {
    const [visivel, setVisivel] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', capturarScroll, { passive: true })
        return () => window.removeEventListener('scroll', capturarScroll)
    }, [])

    const capturarScroll = () => {
        setVisivel(window.scrollY > 100)
    }

    const voltarParaTopo = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <div className="fixed bottom-4 right-4 z-50">
            <AnimatePresence initial={false}>
                {visivel && (
                    <motion.button
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0 }}
                        whileHover={{ translateY: -5 }}
                        className="bg-white border shadow-md text-primary p-4 rounded-full"
                        onClick={voltarParaTopo}>
                        <FaArrowUp />
                    </motion.button>
                )}
            </AnimatePresence>
        </div>
    )
}
