import React from "react"

interface IParametros {
    imagem: string
    titulo: string
    texto: string
    link?: string
}

export default function CartaoModulo({
    imagem,
    titulo,
    texto,
    link
}: IParametros) {

    return (
        <article className="m-1 flex-1 border border-slate-200 flex items-center flex-col p-4 rounded justify-between min-w-[200px]">
            <img
                className="w-3/4"
                src={imagem}
                alt={`Módulo ${titulo}`}
            />
            <h2 className="text-xl my-2">{titulo}</h2>
            <p className="text-sm text-justify flex-1">{texto}</p>
            {
                !!link ?
                    <a className="botao-a" href={link}>Conhecer</a>
                    :
                    <span className="botao-a bg-gray-200 text-gray-500 hover:bg-gray-200 hover:text-gray-500">Em breve</span>
            }
        </article>
    )
}