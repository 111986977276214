import { Cabecalho, Rodape } from './Layout'

export function DivLayout(props: { children: React.ReactNode }) {
    return (
        <div>
            <Cabecalho />
            <div>{props.children}</div>
            <Rodape />
        </div>
    )
}
