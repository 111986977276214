import Marquee from 'react-fast-marquee'

import Preco from '../components/Preco'
import CartaoModulo from '../components/CartaoModulo'

import clientes from '../json/modulos/clientes.json'
import { DivLayout } from '../components/divLayout'

export default function Inicio() {
    return (
        <DivLayout>
            <div className="text-center pt-20 text-xl mx-8 flex items-center justify-center">
                <div className="max-w-7xl flex flex-col items-center">
                    <img className="h-80" src="assets/agulhao/humaaans/Softskills-amico.svg" alt="Sistema Skills" />
                    <div>
                        <h1 className="text-3xl font-light">Tecemos seu negócio</h1>
                        <p className="mt-3 font-light">
                            Nossa equipe irá te ajudar a otimizar o fluxo de trabalho, reduzir custos e aumentar a eficácia da sua empresa
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center flex-col mx-4 mt-14">
                <div className="flex flex-col sm:flex-row max-w-7xl w-full">
                    <CartaoModulo
                        titulo="Indústrias"
                        texto="Fazer a gestão das vendas e Gerenciar sua produção nunca foi tão fácil! Evite desperdícios de matéria-prima e não perca dinheiro produzindo além da necessidade. O Agulhão vai te ajudar a organizar a entrada de pedidos, reduzir os custos com digitação, eliminar os erros operacionais, e fortalecer suas vendas."
                        imagem="assets/agulhao/humaaans/Manufacturing Process-amico.svg"
                        link="/industria"
                    />
                    <CartaoModulo
                        titulo="Lojas"
                        texto="Seja sua loja pequena, média ou grande. Individual ou uma Rede, nós temos a solução ideal! Entradas automatizadas Transferências, Gestão de Condicionais, pré-vendas e Vendas no balcão, Emissão de NF-e ou NFC-e com apenas 2 cliques. Dashboards personalizados para você ficar por dentro de tudo que acontece na sua loja!"
                        imagem="assets/agulhao/humaaans/Shopping-pana.svg"
                        link="/loja"
                    />
                    <CartaoModulo
                        titulo="Facções"
                        texto="Quer ter a sua empresa de costura na palma da mão? Folha de pagamento, Gestão de Ordens de Corte, Entregas, Controle de produção, não serão mais um problema para você! Emita Notas fiscais de Devoluções e Industrialização com apenas 2 cliques!"
                        imagem="assets/agulhao/humaaans/Seamstress-pana.svg"
                    />
                    {/* <CartaoModulo
                        titulo='Aplicativo'
                        texto='Tenha em mãos uma excelente ferramenta para ofereçer aos seus representantes. Trabalhe Online ou Offline com toda a segurança e agilidade! Galeria de Fotos, Rankings e Estatísticas serão ótimas aliadas para alavancar ainda mais suas vendas!'
                        imagem='https://img.icons8.com/3d-fluency/512/smartphone-tablet.png'
                    /> */}
                </div>
            </div>
            <div className="bg-gradient-to-r from-slate-100  to-slate-200 bg-center bg-cover flex items-center justify-center relative mt-12 sm:mt-24 text-slate-800 text-xl text-center font-light">
                <div className="max-w-7xl p-5 my-24 flex flex-col items-center justify-center">
                    <p className="mb-7 text-2xl sm:text-3xl font-bold">
                        Com o Agulhão, você tem a tranquilidade de saber que o seu negócio está sempre em segurança, tudo é feito para obter os
                        melhores resultados
                    </p>
                    <p className="font-light">Teste o Agulhão hoje mesmo e veja como ele pode transformar o seu negócio</p>
                    <a
                        className="bg-emerald-600 text-emerald-50 text-center px-5 py-2 rounded-md text-base font-normal mt-7 hover:bg-emerald-800 shadow-md transition duration-500"
                        href="https://bit.ly/3iYeQJT">
                        Quero uma demonstração agora!
                    </a>
                </div>
            </div>
            <div className="mt-28">
                <h2 className="font-light text-center text-3xl mb-16 px-4">
                    Empresas que confiam no <span className="text-primary font-bold">Agulhão</span>
                </h2>
                <Marquee>
                    {clientes.map((cliente, i) => (
                        <img
                            className="max-h-28 mx-4 transition duration-500 rounded"
                            src={`https://api.agulhao.com.br/public/logotipos/${cliente}.png`}
                            key={i}
                            alt={`Cliente ${cliente}`}
                        />
                    ))}
                </Marquee>
            </div>
            <div className="flex items-center justify-center">
                <Preco />
            </div>
        </DivLayout>
    )
}
