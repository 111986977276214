import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import './index.css'

import Inicio from './pages/inicio'
import Loja from './pages/loja'
import Industria from './pages/industria'
import { VolteParaTopo } from './components/Topo'
import { Nuvemshop } from './pages/hooks/nuvemshop'
import { Toaster } from 'react-hot-toast'

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Inicio />} />
                <Route path="/loja" element={<Loja />} />
                <Route path="/industria" element={<Industria />} />
                <Route path="/hooks/nuvemshop" element={<Nuvemshop />} />
            </Routes>

            <VolteParaTopo />
            <Toaster />
        </Router>
    )
}

export default App
