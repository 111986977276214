import React from "react"
import Lottie from "lottie-react"

import foguete from "../json/animacoes/94719-rocket.json"

import { BsWhatsapp } from "react-icons/bs"

export default function CartaoModulo() {

    return (
        <div className="bg-white max-w-7xl w-full flex flex-col-reverse lg:flex-row items-center justify-center my-12">
            <div>
                <div className="text-center">
                    <p className="text-3xl font-light">Invista no que faça seu negócio</p>
                    <p className="text-3xl font-bold text-primary">crescer e voar</p>
                </div>
                <div className="h-1 rounded-full my-12 w-full bg-slate-100" />
                <div className="flex flex-col items-center justify-center px-5">
                    <p className="text-center">
                        Para agendar uma demonstração e falarmos sobre preços é só
                        chamar!
                    </p>
                    <a className="botao-a text-lg p-4 mt-5 flex items-center justify-center font-light shadow-md" href="https://bit.ly/3iYeQJT" target="_blank" rel="noreferrer">
                        Chamar para uma conversa sem compromisso
                        <BsWhatsapp className="text-xl ml-3" />
                    </a>
                </div>
            </div>

            <Lottie className="lg:ml-24 h-96 lg:h-4/5" animationData={foguete} />
        </div>
    )
}